<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <!--start content-->
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 예약 관리</h5>
              </div>
              <div class="card-body bg-light border-top">
                <div class="row align-items-center">
                  <div class="col d-flex align-items-center">
                    <h5 class="card-title">{{ program.title }}</h5>
                  </div>
                  <div class="col-auto">
                    <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`"> {{ $func.getProgramStatusLabel(parseInt(program.status)) }}</span>
                    <small>{{ `시작일 : ${$func.getShortDate(program.startDate)}` }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer border-top">
                <a class="btn btn-falcon-default btn-sm ms-2" :href="`/admin/programs`"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 상품 목록</h5>
              </div>
              <div class="card-body pt-0">
                <form class="row gy-2 gx-3 mb-3 align-items-center">
                  <div class="col-auto">
                    <label class="form-label" for="basicOption">기본옵션</label>
                    <select class="form-select form-select-sm" v-model="option1ValueId" id="basicOption" @change="getReservationStatusByProduct">
                      <option value="-1" selected="selected">전체</option>
                      <option :key="optionValue.id" :value="optionValue.id" v-for="optionValue in option1Values">{{ optionValue.value }}</option>
                    </select>
                  </div>
                  <div class="col-auto">
                    <label class="form-label" for="extraOption">추가옵션</label>
                    <select class="form-select form-select-sm" v-model="option2ValueId" id="extraOption" @change="getReservationStatusByProduct">
                      <option value="-1" selected="selected">전체</option>
                      <option :key="optionValue.id" :value="optionValue.id" v-for="optionValue in option2Values">{{ optionValue.value }}</option>
                    </select>
                  </div>
                </form>
                <div class="table-responsive scrollbar">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-center">기본옵션</th>
                        <th class="text-center">추가옵션</th>
                        <th class="text-center">추가금액</th>
                        <th class="text-center">옵션수량</th>
                        <th class="text-center">옵션남은수량</th>
                        <th class="text-center">예약</th>
                        <th class="text-center">대기</th>
                        <th class="text-center">취소요청</th>
                        <th class="text-center">취소</th>
                        <th class="text-center">메모</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="productItem in productItemList" :key="productItem.id">
                        <td class="text-center">{{ productItem.option1Value }}</td>
                        <td class="text-center">{{ productItem.option2Value }}</td>
                        <td class="text-end">{{ parseInt(productItem.extraPrice).toLocaleString('ko-KR') }}</td>
                        <td class="text-end">{{ productItem.quantity }}</td>
                        <td class="text-end">{{ productItem.quantity - productItem.reservedQuantity }}</td>
                        <td class="text-end">
                          <a href="#" @click.prevent="getUserReservationData(productItem, 2)">{{ productItem.reservedCount }}</a>
                        </td>
                        <td class="text-end">
                          <a href="#" @click.prevent="getUserReservationData(productItem, 1)">{{ productItem.waitingCount }}</a>
                        </td>
                        <td class="text-end">
                          <a href="#" @click.prevent="getUserReservationData(productItem, 3)">{{ productItem.requestCancelCount }}</a>
                        </td>
                        <td class="text-end">
                          <a href="#" @click.prevent="getUserReservationData(productItem, 4)">{{ productItem.cancelledCount }}</a>
                        </td>
                        <td class="text-end">
                          <a href="#" @click.prevent="getProductMemoList(productItem)">{{ productItem.memoCount }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mb-3" v-show="selectedProductItem.id && selectedStatus == 1">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">예약대기</h5>
              </div>
              <div class="card-body pt-0">
                <h5 class="fs-0 fw-normal">기본옵션 : {{ selectedProductItem.option1Value }} {{ selectedProductItem.option2Value ? ' - 추가옵션 : ' + selectedProductItem.option2Value : '' }}</h5>
                <div class="table-responsive scrollbar mt-3">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-center">예약번호</th>
                        <th class="text-center">예약자</th>
                        <th class="text-center">연락처</th>
                        <th class="text-center">가격</th>
                        <th class="text-center">수량</th>
                        <th class="text-center">결제금액</th>
                        <th class="text-center">등록일자</th>
                        <th class="text-center">입금확인</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                        <td class="text-nowrap">{{ userReservation.reservationNo }}</td>
                        <td class="text-nowrap">{{ userReservation.booker }}</td>
                        <td class="text-nowrap">{{ userReservation.contact }}</td>
                        <td class="text-end">{{ parseInt(userReservation.price).toLocaleString('ko-KR') }}</td>
                        <td class="text-end">{{ userReservation.quantity }}</td>
                        <td class="text-end">{{ parseInt(userReservation.payout).toLocaleString('ko-KR') }}</td>
                        <td class="text-nowrap">{{ userReservation.createTime }}</td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#modalConfirmPayment" href="#" @click.prevent="setParams(userReservation)">
                            <span style="font-size: smaller">입금확인</span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mb-3" v-show="selectedProductItem.id && selectedStatus == 2">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">예약완료</h5>
              </div>
              <div class="card-body pt-0">
                <h5 class="fs-0 fw-normal">기본옵션 : {{ selectedProductItem.option1Value }} {{ selectedProductItem.option2Value ? ' - 추가옵션 : ' + selectedProductItem.option2Value : '' }}</h5>
                <div class="table-responsive scrollbar mt-3">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-center">예약번호</th>
                        <th class="text-center">결제종류</th>
                        <th class="text-center">예약자</th>
                        <th class="text-center">연락처</th>
                        <th class="text-center">수량</th>
                        <th class="text-center">결제금액</th>
                        <th class="text-center">정산금액</th>
                        <th class="text-center">결제일자</th>
                        <th class="text-center">특이사항</th>
                        <th class="text-center">예약취소</th>
                        <th class="text-center">출석</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                        <td class="text-center">{{ userReservation.reservationNo }}</td>
                        <td class="text-center">{{ userReservation.paymentMethod == 1 ? '카드결제' : '무통장입금' }}</td>
                        <td class="text-center">{{ userReservation.booker }}</td>
                        <td class="text-center">{{ userReservation.contact }}</td>
                        <td class="text-end">{{ userReservation.quantity }}</td>
                        <td class="text-end">{{ parseInt(userReservation.payout).toLocaleString('ko-KR') }}</td>
                        <td class="align-middle">
                          <input
                            class="form-control form-control-sm border-0 text-end"
                            style="width: 100px"
                            type="text"
                            v-model="userReservation.settlement"
                            @keypress="$func.inputNumberOnly($event)"
                            @change="updateSettlement(userReservation)"
                          />
                        </td>
                        <td class="text-center">{{ $func.getShortDateTime(userReservation.createTime) }}</td>
                        <td class="text-center">
                          <a
                            v-if="userReservation.uniqueness"
                            class="btn btn-sm btn-outline-primary rounded-pill"
                            data-bs-toggle="modal"
                            data-bs-target="#modalUniqueness"
                            href="#"
                            @click.prevent="setTempUniqueness(userReservation.uniqueness)"
                            ><span style="font-size: smaller">특이사항</span>
                          </a>
                          <span style="font-size: smaller" v-else>-</span>
                        </td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#modalCancelPayment" href="#" @click.prevent="setParams(userReservation)"
                            ><span style="font-size: smaller">예약취소</span></a
                          >
                        </td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#modalAttendance" href="#" @click.prevent="setParams(userReservation)"
                            ><span style="font-size: smaller">{{ userReservation.isCompletion ? '출석취소' : '출석' }}</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mb-3" v-show="selectedProductItem.id && selectedStatus == 3">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">에약취소요청</h5>
              </div>
              <div class="card-body pt-0">
                <h5 class="fs-0 fw-normal">기본옵션 : {{ selectedProductItem.option1Value }} {{ selectedProductItem.option2Value ? ' - 추가옵션 : ' + selectedProductItem.option2Value : '' }}</h5>
                <div class="table-responsive scrollbar mt-3">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-nowrap">예약번호</th>
                        <th class="text-center">예약자</th>
                        <th class="text-center">연락처</th>
                        <th class="text-center">가격</th>
                        <th class="text-center">수량</th>
                        <th class="text-center">결제금액</th>
                        <th class="text-center">요청일자</th>
                        <th class="text-center">환불정보</th>
                        <th class="text-center">환불확인</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                        <td class="text-nowrap">{{ userReservation.reservationNo }}</td>
                        <td class="text-nowrap">{{ userReservation.booker }}</td>
                        <td class="text-nowrap">{{ userReservation.contact }}</td>
                        <td class="text-end">{{ parseInt(userReservation.price).toLocaleString('ko-KR') }}</td>
                        <td class="text-end">{{ userReservation.quantity }}</td>
                        <td class="text-end">{{ parseInt(userReservation.payout).toLocaleString('ko-KR') }}</td>
                        <td class="text-nowrap">{{ userReservation.createTime }}</td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#modalRefundInfo" href="#" @click.prevent="setParams(JSON.parse(userReservation.cancelDetail))"
                            ><span style="font-size: smaller">환불정보</span></a
                          >
                        </td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#modalConfirmRefund" href="#" @click.prevent="setParams(userReservation)"
                            ><span style="font-size: smaller">환불확인</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mb-3" v-show="selectedProductItem.id && selectedStatus == 4">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">예약취소</h5>
              </div>
              <div class="card-body pt-0">
                <h5 class="fs-0 fw-normal">기본옵션 : {{ selectedProductItem.option1Value }} {{ selectedProductItem.option2Value ? ' - 추가옵션 : ' + selectedProductItem.option2Value : '' }}</h5>
                <div class="table-responsive scrollbar mt-3">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-nowrap">예약번호</th>
                        <th class="text-nowrap">결제종류</th>
                        <th class="text-center">예약자</th>
                        <th class="text-center">연락처</th>
                        <th class="text-center">가격</th>
                        <th class="text-center">수량</th>
                        <th class="text-center">결제금액</th>
                        <th class="text-center">취소일자</th>
                        <th class="text-center">환불정보</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                        <td class="text-nowrap">{{ userReservation.reservationNo }}</td>
                        <td class="text-nowrap">{{ userReservation.paymentMethod == 1 ? '카드결제' : '무통장입금' }}</td>
                        <td class="text-nowrap">{{ userReservation.booker }}</td>
                        <td class="text-nowrap">{{ userReservation.contact }}</td>
                        <td class="text-end">{{ parseInt(userReservation.price).toLocaleString('ko-KR') }}</td>
                        <td class="text-end">{{ userReservation.quantity }}</td>
                        <td class="text-end">{{ parseInt(userReservation.payout).toLocaleString('ko-KR') }}</td>
                        <td class="text-nowrap">{{ userReservation.cancelTime }}</td>
                        <td class="text-center">
                          <a
                            v-if="userReservation.cancelDetail"
                            class="btn btn-sm btn-outline-primary rounded-pill"
                            data-bs-toggle="modal"
                            data-bs-target="#modalRefundInfo"
                            href="#"
                            @click.prevent="setParams(JSON.parse(userReservation.cancelDetail))"
                            ><span style="font-size: smaller">환불정보</span>
                          </a>
                          <span v-else style="font-size: smaller">-</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mb-3" v-show="selectedProductItem.id && selectedStatus == 0">
              <div class="card-header d-flex">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">메모</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row align-items-center">
                  <div class="col d-flex align-items-center">
                    <h5 class="fs-0 fw-normal">기본옵션 : {{ selectedProductItem.option1Value }} {{ selectedProductItem.option2Value ? ' - 추가옵션 : ' + selectedProductItem.option2Value : '' }}</h5>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-falcon-default btn-sm" data-bs-toggle="modal" data-bs-target="#modalProductMemo" href="#" @click.prevent="newMemo()"> <span class="fas fa-plus-circle me-1"></span>새로운 메모</a>
                  </div>
                </div>

                <div class="table-responsive scrollbar mt-3">
                  <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                    <thead>
                      <tr>
                        <th class="text-nowrap" style="width: 80px">번호</th>
                        <th class="text-nowrap" style="width: 100px">작성자</th>
                        <th class="text-center">메모</th>
                        <th class="text-center" style="width: 120px">생성일자</th>
                        <th class="text-center" style="width: 80px">수정</th>
                        <th class="text-center" style="width: 80px">삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="productMemo in productMemoList" :key="productMemo.id">
                        <td class="text-nowrap">{{ productMemo.id }}</td>
                        <td class="text-nowrap">{{ productMemo.userName }}</td>
                        <td class="text-nowrap" v-html="productMemo.memo"></td>
                        <td class="text-nowrap">{{ productMemo.createTime }}</td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" v-show="productMemo.userUid == user.uid" data-bs-toggle="modal" data-bs-target="#modalProductMemo" href="#" @click.prevent="setParams(productMemo)"
                            ><span style="font-size: smaller">수정</span>
                          </a>
                        </td>
                        <td class="text-center">
                          <a class="btn btn-sm btn-outline-primary rounded-pill" v-show="productMemo.userUid == user.uid" data-bs-toggle="modal" data-bs-target="#modalDeleteMemo" href="#" @click.prevent="setParams(productMemo)"
                            ><span style="font-size: smaller">삭제</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDialog :modalId="'modalCancelPayment'" :title="'예약취소'" :message="'예약을 취소하시겠습니까?'" :params="params" @DialogOk="cancelReservation"></ModalDialog>
        <ModalDialog :modalId="'modalConfirmRefund'" :title="'환불확인'" :message="'환불을 확인하시겠습니까?'" :params="params" @DialogOk="confirmRefund"></ModalDialog>
        <ModalDialog :modalId="'modalConfirmPayment'" :title="'입금확인'" :message="'입금을 확인하시겠습니까?'" :params="params" @DialogOk="confirmPayment"></ModalDialog>
        <ModalDialog :modalId="'modalDeleteMemo'" :title="'메모삭제'" :message="'메모를 삭제하시겠습니까?'" :params="params" @DialogOk="deleteProductMemo"></ModalDialog>
        <RefundInfoDialog :refundInfo="params"></RefundInfoDialog>
        <ProgramProductMemoDialog :productMemo="params" @saveMemo="saveProductMemo"></ProgramProductMemoDialog>
        <AttendanceDialog :userReservation="params" @attendance="attendance"></AttendanceDialog>
        <UniquenessDialog :uniqueness="tempUniqueness"></UniquenessDialog>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import UniquenessDialog from '@/components/admin/program/UniquenessDialog.vue';
import RefundInfoDialog from '@/components/admin/program/RefundInfoDialog.vue';
import ProgramProductMemoDialog from '@/components/admin/program/ProgramProductMemoDialog.vue';
import AttendanceDialog from '@/components/admin/program/AttendanceDialog.vue';
import { ko } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
export default {
  name: 'program-reservation',
  components: {
    Header,
    Navigation,
    FooterContent,
    ModalDialog,
    UniquenessDialog,
    RefundInfoDialog,
    ProgramProductMemoDialog,
    AttendanceDialog,
    moment,
    Datepicker,
  },
  data() {
    return {
      user: {},
      programId: -1,
      program: {},
      option1Values: [],
      option2Values: [],
      option1ValueId: -1,
      option2ValueId: -1,
      productItemList: [],
      selectedProductItem: {},
      selectedStatus: 0,
      userReservationItems: [],
      productMemoList: [],
      params: {},
      tempUniqueness: '',
      ko: ko,
      highlighted: {
        dates: [
          // Highlight an array of dates
          new Date(),
        ],
      },
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
    this.user = this.$func.getUser();
    this.getProgramReservationData();
  },
  methods: {
    getProgramReservationData() {
      //console.log('getProgramReservationData');
      this.$http
        .get('/admin-api/get-program-reservation-data.nolto', {
          params: {
            programId: this.programId,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.program = response.data.program;
          this.option1Values = response.data.option1Values;
          this.option2Values = response.data.option2Values;
          this.getReservationStatusByProduct();
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    getReservationStatusByProduct() {
      //console.log('getReservationStatusByProduct', this.option1ValueId, this.option2ValueId);
      this.$http
        .get('/admin-api/get-program-reservation-status.nolto', {
          params: {
            programId: this.programId,
            option1ValueId: this.option1ValueId,
            option2ValueId: this.option2ValueId,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.productItemList = response.data.reservationStatusItems;
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    getUserReservationData(productItem, status) {
      //console.log('getUserReservationData', productItem, status);
      this.selectedProductItem = productItem;
      this.selectedStatus = status;
      this.$http
        .get('/admin-api/get-user-reservations.nolto', {
          params: {
            productId: this.selectedProductItem.id,
            status: this.selectedStatus,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.userReservationItems = response.data.userReservationItems;
          this.productMemoList = [];
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },

    confirmPayment(userReservation) {
      //console.log('confirmPayment-userReservation:', userReservation.id);
      this.$http
        .post('/admin-api/confirm-payment.nolto', {
          userReservationId: userReservation.id,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getUserReservationData(this.selectedProductItem, this.selectedStatus);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    cancelReservation(userReservation) {
      //console.log('cancelReservation-userReservation:', userReservation.id);
      this.$http
        .post('/admin-api/cancel-reservation.nolto', {
          userReservationId: userReservation.id,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getUserReservationData(this.selectedProductItem, this.selectedStatus);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    attendance(userReservation) {
      //console.log('attendance-userReservation:', userReservation.id);
      this.$http
        .post('/admin-api/attendance-program.nolto', {
          userReservationId: userReservation.id,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getUserReservationData(this.selectedProductItem, this.selectedStatus);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    updateSettlement(userReservation) {
      //console.log('update-settlement:', userReservation.id);
      this.$http
        .post('/admin-api/update-settlement.nolto', {
          userReservationId: userReservation.id,
          settlement: userReservation.settlement,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getUserReservationData(this.selectedProductItem, this.selectedStatus);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    confirmRefund(userReservation) {
      //console.log('confirmRefund-userReservation:', userReservation.id);
      this.$http
        .post('/admin-api/confirm-refund.nolto', {
          userReservationId: userReservation.id,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getUserReservationData(this.selectedProductItem, this.selectedStatus);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    getProductMemoList(productItem) {
      //console.log('getProductMemoList', productItem);
      this.selectedProductItem = productItem;
      this.selectedStatus = 0;
      this.$http
        .get('/admin-api/get-program-product-memo-list.nolto', {
          params: {
            productId: this.selectedProductItem.id,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.productMemoList = response.data.productMemoList;
          //console.log('productMemoList', this.productMemoList);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    setParams(params) {
      //console.log('setParams-params:', params);
      return (this.params = params);
    },

    setTempUniqueness(uniqueness) {
      //console.log('setTempUniqueness-uniqueness:', uniqueness);
      return (this.tempUniqueness = uniqueness);
    },

    newMemo() {
      //console.log('.newMemo - selectedProductItem : ' + this.selectedProductItem);
      if (this.selectedProductItem.id) {
        let productMemo = { id: -1, programProductId: this.selectedProductItem.id, userUid: '', memo: '' };
        this.setParams(productMemo);
        return;
      }
      this.$toast.warning('선택된 상품이 없습니다.');
    },

    saveProductMemo(productMemo) {
      //console.log('saveProductMemo-productMemo:', productMemo);
      if (productMemo.id > 0) {
        if (productMemo.userUid != this.$func.getUser().uid) {
          this.$toast.warning('자신의 글이 아닙니다.');
          return;
        }
      } else {
        productMemo.userUid = this.$func.getUser().uid;
      }
      //console.log('productMemo:', productMemo);
      this.$http
        .post('/admin-api/save-product-memo.nolto', productMemo)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getProductMemoList(this.selectedProductItem);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    deleteProductMemo(productMemo) {
      //console.log('deleteProductMemo-productMemo:', productMemo);
      this.$http
        .post('/admin-api/delete-product-memo.nolto', productMemo)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getReservationStatusByProduct();
          this.getProductMemoList(this.selectedProductItem);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    validateLinkDate(date) {
      if (this.$func.fromNowDays(date) >= 0) {
        this.$toast.warning('링크 만료일자는 오늘 이후로 선택해야합니다.');
      }
    },
  },
};
</script>
